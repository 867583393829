<template>
  <p
    class="text-[64px] font-bold w-12 h-10 flex justify-center items-center text-white"
  >
    {{ gameResult }}x
  </p>
</template>

<script setup>
const fairnessStore = useFairnessVerificationStore();
const { crashResult, } = fairnessStore;

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
});

const gameResult = ref(null);

watchEffect(() => {
  if (props.result) {
    gameResult.value = crashResult(props.result);
  }
});
</script>
