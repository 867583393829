<template>
  <div class="diamonds-container flex items-center justify-evenly bg-slate-900 border border-slate-400 rounded-lg h-[50px] w-full py-[5px]">
    <img
      v-for="(diamond, index) in diamonds"
      :key="index"
      :src="`${assetUrl}/diamonds/assets/history/diamonds/${diamond}.svg`"
      :alt="diamond"
      class="diamond h-full flex-1"
    >
  </div>
</template>

<script setup>
const fairnessStore = useFairnessVerificationStore();
const { diamondsResult, } = fairnessStore;
const gameStore = useGamesStore();
const { assetUrl, } = storeToRefs(gameStore);

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
});

const diamonds = computed(() => diamondsResult(props.result));
</script>
